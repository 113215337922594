.PageTechnologies {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2vh;
    height: 60vh;
	color: black;
	text-align: start;
    overflow-y: scroll;
    margin-left: 7vw;
    margin-right: 7vw;
    scrollbar-width: none;
}

.PageTechnologies::-webkit-scrollbar {
	width: 0px;
}