.big_Fon {
	background: url('../../images/fon.png');
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.77;
	z-index: -2;
	min-height: 100vh;
	grid-area: 1 / 1 / 5 / 3;
}

.small_Fon {
	background: url('../../images/fon.png');
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.77;
	z-index: -2;
	min-height: 100vh;
	grid-area: 1 / 1 / 5 / 3;
}
