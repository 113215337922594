.big_Logo {
	grid-area: 1 / 1 / 3 / 2;
	margin: 1vh;
	background: url(../../images/logo.png);
	background-size: 52%;
	background-repeat: no-repeat;
	background-position: 3vh ;
	min-height: 3.7vw;
}


.small_Logo {
	grid-area: 1 / 1 / 3 / 3;
	margin-top: 0px;
	margin-left: 0.5vh;
	background: url(../../images/logo.png);
	background-size: 20%;
	background-repeat: no-repeat;
	background-position: 3%;
}