.big_Content {
	display: grid;
	grid-template-columns: 0.4fr 1.5fr;
	grid-template-rows: 5vh 4vw 1fr 5.7vh;
}

.small_Content {
	display: grid;
	grid-template-columns: 33% 67%;
	grid-template-rows: 8vw 1.9vw 1fr 4vw;
}