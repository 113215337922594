.big_Slogan {
	grid-area: 1 / 2 / 2 / 3;
	justify-self: end;
	/* justify-self: center; */
}

/* .small_Slogan {
	grid-area: 2 / 1 / 3 / 3;
	justify-self: start;
} */


.big_slogan {
	font-size: 2.7vh;
	font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	margin-top: 2vh;
    margin-right: 10vh;
	color: rgba(211, 15, 15, 0.753);
}

/* .small_slogan {
	font-size: 1vw;
	font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	color: rgba(211, 15, 15, 0.753);
	justify-self: start;
	margin-left: 3.5vw;
} */