.ArticleShow {
	grid-area: 3 / 1 / 4 / 3;
	background: white;
	opacity: 0.97;
	margin-top: 1.5vh;
	margin-right: 2vh;
	margin-left: 2vh;
	margin-bottom: 1.2vh;
	border-radius: 4px 4px 4px 4px;
	/* margin-bottom: 7vh; */
	height: fit-content;
	align-self: center;

}

.article {
    padding: 10px;
    /* text-align: center; */
  }