
.Button {
	grid-area: 2 / 2 / 3 / 3;
    justify-self: end;
    margin-top: -10%;
    margin-right: 3vw;
    align-self: center;
}

button {
    /* position: relative; */
    /* left: 2vw; */
    border: none;
    /* top: 15px ; */
    background-color: rgba(185, 180, 180, 0);
    text-decoration: none;
    cursor: pointer;
	opacity: 0.9;
    min-width: 3vh;
    min-height: 4wh;
}

button div{
    width: 35px;
    height: 4px;
    background-color:#7c7777f6;
    margin: 4px;
    border-radius: 3px;
}